import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import Icon from "atlas/components/Icon/Icon";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";
import { useDroppable } from "@dnd-kit/core";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import MoveButtons from "./CommonComponents/MoveButtons";
import { Presentation } from "../Icons";
import { handleKeyDownDND, handleListItemKeyDown, stripTags } from "./CommonComponents/tocUtils";
import TocContentWrapper from "./CommonComponents/TocContentWrapper";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCDecision = (props) => {
	const {
		resolution,
		toggleTableOfContent = () => {},
		isConsentSection,
		isPublicCommentSection,
		isMemberOnlySection,
		isSmallDevice,
		isHeadingAction,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
		openGoalsPopup,
		canDrag = false,
		canDrop = false,
		isLastChildItem = false,
		isActive = false,
		moveItem = () => {},
		getNextAvailablePosition = () => {},
		isAgenda = false,
		isMinutes = false,
		presenting = false,
		clone = false,
		renderControl = {},
		index,
		getOverallTabIndex = () => {},
	} = props;
	const dispatch = useDispatch();

	const tableOfContentsClasses = useTableOfContentsStyles({
		lineLimit: renderControl?.lineLimit ? renderControl.lineLimit : resolution?.fields?.Name?.Value?.length > 50 ? 2 : 1,
	});
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);
	const [hoverStatus, setHoverStatus] = useState(false);
	const [arrowNavigation, setArrowNavigation] = useState(true);

	const dragProps = canDrag ? { dragId: resolution.guid, dragComponent: "div", tabIndex: null } : {};

	const droppable = useDroppable({ id: resolution.guid });
	const { setNodeRef } = canDrop ? droppable : {};

	const handleBlur = () => {
		if (arrowNavigation) {
			setHoverStatus(false);
		}
	};

	const handleMove = (item, isMoveUp) => {
		setHoverStatus(false);

		if (isAgenda) {
			telemetryAddEvent("Agenda builder reorder - outline button");
		} else {
			telemetryAddEvent("Minutes builder reorder - outline button");
		}

		moveItem(item, isMoveUp);
	};

	const ConditionForNonOutline = !canDrag && !(isAgenda || isMinutes);
	const ConditionForOutline = canDrag || isAgenda || isMinutes;
	const showAttachments = clone ? false : true;

	const getBulletAndText = () => (
		<>
			<ListItemIcon className={tableOfContentsClasses.bullet} style={{ minWidth: "0", top: "4px!important" }}>
				<Icon name="recommendation" />
			</ListItemIcon>
			<ListItemText
				className={clsx({
					[tableOfContentsClasses.limitLines]: Object.keys(renderControl).length <= 0,
					[tableOfContentsClasses.primaryTextEllipses]: Object.keys(renderControl).length > 0,
				})}
				primaryTypographyProps={{ variant: "h5" }}
				primary={
					<TOCPrimaryText
						text={`<p>${stripTags(resolution.fields.Name.Value, ["a"])}</p>`}
						parserOptions={parserOptions}
						showTruncationTooltip
						truncationThreshold={isPdfSidebar ? (!renderControl ? 23 : 71) : 50}
						isPdfSidebar={isPdfSidebar}
						renderControl={Object.keys(renderControl).length > 0}
					></TOCPrimaryText>
				}
			/>
		</>
	);

	return (
		<TocContentWrapper
			condition={isAgenda || isMinutes}
			wrapper="span"
			wrapperProps={{
				id: `${resolution.guid}-toc-decision`,
				ref: setNodeRef,
				onKeyDown: (e) => handleListItemKeyDown(e, setArrowNavigation),
			}}
		>
			<ListItem
				id={`${idPrefix}-${resolution.guid}`}
				{...(renderControl && resolution.isFirstElementForSkipContent && { id: "cont" })}
				data-cy={`${idPrefix}-${resolution.guid}`}
				className={clsx(tableOfContentsClasses.listItem, {
					[tableOfContentsClasses.closedChanged]: resolution.original && resolution.original.closed && !resolution.fields.Closed.Value,
					[tableOfContentsClasses.backgroundColorForSelected]:
						renderControl && renderControl.highlightCheck && renderControl.highlightCheck(resolution),
				})}
				button={button}
				role=""
				component={"li"}
				disableGutters
				divider
				classes={{
					root: clsx(
						ConditionForOutline ? "candrag-outline-item-secondary" : "outline-item-secondary",
						ConditionForNonOutline &&
							resolution.copied &&
							renderControl?.isAnimationAdded(document.getElementById(`${idPrefix}-${resolution.guid}`))
							? "transition-animation"
							: resolution.copied && renderControl
								? "transition-animation-added"
								: "",
						{
							"toc-padding": hoverStatus && canDrag,
							"toc-active-item": isActive && ConditionForOutline,
							"outline-item-consent-recommendation": isConsentSection && !ConditionForOutline,
							"candrag-outline-item-consent-recommendation": isConsentSection && canDrag,
							"outline-item-public-comment-recommendation": isPublicCommentSection && !isConsentSection && !ConditionForOutline,
							"candrag-outline-item-public-comment-recommendation": isPublicCommentSection && !isConsentSection && canDrag,
							"outline-heading-recommendation": isHeadingAction && !isPublicCommentSection && !isConsentSection && !ConditionForOutline,
							"candrag-outline-heading-recommendation": isHeadingAction && !isPublicCommentSection && !isConsentSection && canDrag,
							"outline-item-recommendation": !ConditionForOutline && !isHeadingAction && !isPublicCommentSection && !isConsentSection,
							"member-only-section": !canDrag && isMemberOnlySection,
							"canDrag-member-only-section": canDrag && isMemberOnlySection,
							"consent-section-outline": isConsentSection,
							"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
							"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
							"closed-meeting": isClosedMeeting,
							"member-only-section-last-child-item":
								isLastChildItem && resolution.attachments.length === 0 && isMemberOnlySection && ConditionForOutline,
							"consent-section-last-child-item":
								isLastChildItem && resolution.attachments.length === 0 && isConsentSection && ConditionForOutline,
							"public-comment-section-last-child-item":
								isLastChildItem && resolution.attachments.length === 0 && isPublicCommentSection && ConditionForOutline,
							pdfSideBar: isPdfSidebar,
							"transition-animation":
								!ConditionForNonOutline &&
								resolution.copied &&
								renderControl?.isAnimationAdded(document.getElementById(`${idPrefix}-${resolution.guid}`)),
							hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(resolution.guid) && !clickedOnAttachment,
							[tableOfContentsClasses.selected]: ConditionForNonOutline && selected && selected.includes(resolution.guid),
							[tableOfContentsClasses.borderOfAttachmentAndRecomm]: ConditionForNonOutline && renderControl,
							[tableOfContentsClasses.itemValueAndTextGapping]: ConditionForNonOutline && renderControl,
							[tableOfContentsClasses.recommendationPaddingForHeading]: ConditionForNonOutline && renderControl && isHeadingAction,
							[tableOfContentsClasses.recommendationPaddingForItems]: ConditionForNonOutline && renderControl && !isHeadingAction,
						},
					),
				}}
				onMouseOver={() => setHoverStatus(true)}
				onMouseOut={() => setHoverStatus(false)}
				onFocus={() => setHoverStatus(true)}
				onBlur={handleBlur}
				onClick={
					button && isPdfSidebar
						? () => {
								setDataForOutlineItem(resolution);
								setClickedOnAttachment(false);
								dispatch(setActive(resolution.guid, true));
							}
						: button
							? () => {
									telemetryAddEvent(`${telemetryPage} - Outline navigation`);

									dispatch(setActive(resolution.guid, true));
									if (isSmallDevice) {
										toggleTableOfContent();
									}
								}
							: undefined
				}
				selected={selected && selected.includes(resolution.guid)}
				tabIndex={getOverallTabIndex(index)}
			>
				{canDrag ? (
					<Draggable className={tableOfContentsClasses.draggableListItem} {...dragProps} handleKeyDown={handleKeyDownDND}>
						<DragHandle role="button" />
					</Draggable>
				) : null}
				{getBulletAndText()}
				{canDrag && (
					<MoveButtons
						moveItem={handleMove}
						item={resolution}
						showMoveUp={getNextAvailablePosition(resolution, true).nextPosition !== null}
						showMoveDown={getNextAvailablePosition(resolution, false).nextPosition !== null}
					/>
				)}
				{presenting && !hoverStatus && selected && selected.includes(resolution.guid) && <Presentation />}
				{renderControl?.isBoardNotesPresent && (
					<span style={{ paddingRight: renderControl.isToAgendaRecommendation ? "16px" : "35px" }}>
						{renderControl.isBoardNotesPresent(resolution)}
					</span>
				)}
				{renderControl?.isCheckBoxVisible &&
					renderControl.isCheckBoxVisible(resolution) &&
					renderControl.rendercomponent(resolution, index)}
			</ListItem>

			{showAttachments && !clone && (
				<TOCAttachments
					attachments={resolution.attachments}
					item={resolution}
					itemGuid={resolution.guid}
					isMemberOnlySection={isMemberOnlySection}
					isConsentSection={isConsentSection}
					isPublicCommentSection={isPublicCommentSection}
					toggleTableOfContent={toggleTableOfContent}
					telemetryPage={telemetryPage}
					setDataForAttachmentDocument={setDataForAttachmentDocument}
					isPdfSidebar={isPdfSidebar}
					setActive={setActive}
					clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
					isRecommendationLevel
					selectedId={selectedId}
					openGoalsPopup={openGoalsPopup}
					isLastChildItem={isLastChildItem}
					canDrag={ConditionForOutline}
					renderControl={renderControl}
					isMeetingItemsChild={isHeadingAction}
				/>
			)}
		</TocContentWrapper>
	);
};

export default TOCDecision;
